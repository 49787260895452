import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import useVWO from "@/hooks/useVWO";

import styles from "./PersonalizationBanner.module.scss";

const PersonalizationBanner = ({
  campaignId,
  excludedUrl,
  linkUrlPlaceholder,
  linkText,
  linkUrl,
  localStorageKey,
  text,
  textPlaceholder,
}) => {
  const [hideBanner, setHideBanner] = React.useState(true);
  const [locationOrigin, setLocationOrigin] = React.useState("");
  const [localStorageValue, setLocalStorageValue] = React.useState(null);

  const isVariantEnabled = useVWO(campaignId);

  useEffect(() => {
    setLocationOrigin(window.location.origin);
    setHideBanner(window.location.href.includes(excludedUrl));

    if (localStorageKey && window.localStorage.getItem(localStorageKey)) {
      setLocalStorageValue(
        JSON.parse(window.localStorage.getItem(localStorageKey))
      );
    }
  }, [excludedUrl, localStorageKey]);

  if (
    !isVariantEnabled ||
    hideBanner ||
    (localStorageKey && !localStorageValue)
  ) {
    return null;
  }

  return (
    <aside className={styles.personalizationBanner}>
      <div className={styles.personalizationBannerInner}>
        <span className={styles.personalizationCopy}>
          {textPlaceholder
            ? text.replace(
                `[${textPlaceholder}]`,
                localStorageValue[textPlaceholder]
              )
            : text}
        </span>

        <a
          className={classNames(
            styles.personalizationCopy,
            styles.personalizationCopyLink
          )}
          data-is="PersonalizationBannerLink"
          href={`${locationOrigin}/${localStorageValue ? linkUrl.replace(`[${linkUrlPlaceholder}]`, localStorageValue[linkUrlPlaceholder]) : linkUrl}`}
        >
          {linkText}
        </a>
      </div>
    </aside>
  );
};

PersonalizationBanner.propTypes = {
  campaignId: PropTypes.string.isRequired,
  excludedUrl: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  linkUrlPlaceholder: PropTypes.string.isRequired,
  localStorageKey: PropTypes.string,
  text: PropTypes.string.isRequired,
  textPlaceholder: PropTypes.string.isRequired,
};

export default PersonalizationBanner;
