import { useEffect, useState } from "react";

const useVWO = (attribute, value) => {
  const attributeName = attribute || "data-is-vwo-variant";
  const attributeValue = value || "true";
  const [isVariant, setIsVariant] = useState(false);

  useEffect(() => {
    const onBodyAttributeMutation = (mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "attributes" &&
          mutation.attributeName === attributeName
        ) {
          const bodyElement = document.body;
          const vwoBodyAttribute = bodyElement.getAttribute(attributeName);

          setIsVariant(vwoBodyAttribute === attributeValue);
        }
      }
    };

    const bodyElement = document.body;
    const vwoBodyAttribute = bodyElement.getAttribute(attributeName);
    setIsVariant(vwoBodyAttribute === attributeValue);

    const observer = new MutationObserver(onBodyAttributeMutation);

    observer.observe(bodyElement, {
      attributeFilter: [attributeName],
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [attributeName, attributeValue]);

  return isVariant;
};

export default useVWO;
